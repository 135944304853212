export const AthletesHowItWorksContent = {
    heading: 'HOW IT WORKS',
    text: 'Here\'s what the program involves.',
    timelineHeadings: [
        {
            heading: 'FACE TO FACE',
            text: 'We deliver two 1-hour workshops to your team, and teach the skills that will help improve your understanding of mental health and resilience.'
        },
        {
            heading: 'ONLINE COURSE',
            text: 'We take you through our top six skills for overcoming challenges in hockey and in life.'
        },
        {
            heading: 'FOLLOW UP SURVEY',
            text: 'Tell us what you thought of the program, including how we can make it better. Your feedback is important to us.'
        }
    ]
};

export const CoachesHowItWorksContent = {
    heading: 'HOW IT WORKS',
    text: 'Here\'s what the program involves.',
    timelineHeadings: [
        {
            heading: 'FACE TO FACE',
            text: 'A one-hour workshop with the coaches of your team. You\'ll learn basic skills that will help you understand, recognize and support mental health and wellbeing in your athletes.'
        },
        {
            heading: 'FOLLOW UP',
            text: 'Tell us what you thought of the program, including how we could make it better.'
        }
    ]
};

export const ParentsHowItWorksContent = {
    heading: 'HOW IT WORKS',
    text: 'Here\'s what the program involves.',
    timelineHeadings: [
        {
            heading: 'FACE TO FACE',
            text: `We deliver a 1-hour workshop to parents on your kid's team. You'll learn basic skills that will help you understand, recognize and support adolescent mental health and well-being.`
        },
        {
            heading: 'FOLLOW UP SURVEY',
            text: 'Tell us what you thought of the program, including how we could make it better.'
        }
    ]
};