import React, { Component } from 'react';

import { homePageContent } from '../../contents/homepage';

import DiagonalSplitImage from '../../images/homepage/Homepage_locker_shot.png';
import DiagonalSplitImageTablet from '../../images/homepage/Homepage_locker_shot_tablet.png';
import DiagonalSplitImageMobile from '../../images/homepage/Homepage_locker_shot_mobile.png';

import { CallToAction } from '../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledHomePage } from './Home.page.style';
import { Size } from '../../typings/Size.type';
import { WhoIsThisFor } from '../../components/WhoIsThisFor/WhoIsThisFor';
import { slides } from '../../contents/whoisthisfor';
import { withRouter } from 'react-router-dom';
import { Hero } from '../../components/Hero/Hero';
import HeroImageMobile from '../../images/homepage/homepage_hero_mobile.jpg';
import { BannerImage } from '../../typings/BannerImage.type';

class HomePage extends Component<any> { 
  render() {
  const { heroBanner, diagonalBanner } = homePageContent;
  
  const banners: BannerImage[] = [
    {
      bannerUrl: HeroImageMobile,
      breakpoint: 'xxs',
      height: 686,
    }
  ];

  const heroBannerCallToAction: CallToAction = {
    heading: heroBanner.heading.toUpperCase(),
    callToActionLabel: heroBanner.callToActionLabel,
    onClick: (): void => this.props.history.push('/contact')
  };

  const diagonalBannerCallToAction: CallToAction = {
    heading: diagonalBanner.heading.toUpperCase(),
    callToActionLabel: diagonalBanner.callToActionLabel,
    subHeading: diagonalBanner.subHeading,
    onClick: (): void => this.props.history.push('/about')
  };

  return (
    <StyledHomePage>
      <Hero callToAction={heroBannerCallToAction} heroBanners={banners} />
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.large} headingLevel="h3"/>
      <WhoIsThisFor slides={ slides }/>
    </StyledHomePage>
  );
  }
}

const home = withRouter(HomePage);

export { home as HomePage };