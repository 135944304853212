import { colors } from './colors.theme';

const headingLineHeightMx = 0.916;

export const BreakingTheIce = {
  name: 'BreakingTheIce',
  breakpoints: {
    xxs: '0px',
    xs: '480px',
    sm: '768px',
    md: '1024px',
    lg: '1280px',
    xl: '1600px'
  },
  colors: {
    ...colors
  },
  typography: {
    defaultFontFamily: 'Passion One',
    heading: {
      h1: {
        fontSize: {
          lg: '5.125rem',
          md: '4.125rem',
          sm: '4.125rem',
          xs: '3.25rem',
          xxs: '3.25rem'
        },
        lineHeight: {
          lg: (5.125 * headingLineHeightMx) + 'rem',
          md: (4.125 * headingLineHeightMx) + 'rem',
          sm: (4.125 * headingLineHeightMx) + 'rem',
          xs: (3.25 * headingLineHeightMx) + 'rem',
          xxs: (3.25 * headingLineHeightMx) + 'rem',
        }
      },
      h2: {
        fontSize: {
          lg: '4.625rem',
          md: '3.75rem',
          sm: '3.75rem',
          xs: '3rem',
          xxs: '3rem'
        },
        lineHeight: {
          lg: (4.625 * headingLineHeightMx) + 'rem',
          md: (3.75 * headingLineHeightMx) + 'rem',
          sm: (3.75 * headingLineHeightMx) + 'rem',
          xs: (3 * headingLineHeightMx) + 'rem',
          xxs: (3 * headingLineHeightMx) + 'rem',
        }
      },
      h3: {
        fontSize: {
          lg: '3.75rem',
          md: '3.375rem',
          sm: '3.375rem',
          xs: '2.75rem',
          xxs: '2.75rem'
        },
        lineHeight: {
          lg: (3.75 * headingLineHeightMx) + 'rem',
          md: (3.375 * headingLineHeightMx) + 'rem',
          sm: (3.375 * headingLineHeightMx) + 'rem',
          xs: (2.75 * headingLineHeightMx) + 'rem',
          xxs: (2.75 * headingLineHeightMx) + 'rem',
        }
      },
      h4: {
        fontSize: {
          lg: '3.25rem',
          md: '3rem',
          sm: '3rem',
          xs: '2.5rem',
          xxs: '2.5rem'
        },
        lineHeight: {
          lg: (3.25 * headingLineHeightMx) + 'rem',
          md: (3 * headingLineHeightMx) + 'rem',
          sm: (3 * headingLineHeightMx) + 'rem',
          xs: (2.5 * headingLineHeightMx) + 'rem',
          xxs: (2.5 * headingLineHeightMx) + 'rem',
        }
      },
      h5: {
        fontSize: {
          lg: '2.75rem',
          md: '2.625rem',
          sm: '2.625rem',
          xs: '2.25rem',
          xxs: '2.25rem',
        },
        lineHeight: {
          lg: (2.75 * headingLineHeightMx) + 'rem',
          md: (2.625 * headingLineHeightMx) + 'rem',
          sm: (2.625 * headingLineHeightMx) + 'rem',
          xs: (2.25 * headingLineHeightMx) + 'rem',
          xxs: (2.25 * headingLineHeightMx) + 'rem',
        }
      },
      h6: {
        fontSize: {
          lg: '2.25rem',
          md: '2.25rem',
          sm: '2.25rem',
          xs: '2rem',
          xxs: '2rem',
        },
        lineHeight: {
          lg: (2.25 * headingLineHeightMx) + 'rem',
          md: (2.25 * headingLineHeightMx) + 'rem',
          sm: (2.25 * headingLineHeightMx) + 'rem',
          xs: (2 * headingLineHeightMx) + 'rem',
          xxs: (2 * headingLineHeightMx) + 'rem',
        }
      }
    },
    text: {
      normal: {
        fontSize: {
          lg: '1rem',
          md: '1rem',
          sm: '1rem',
          xs: '1rem',
          xxs: '1rem'
        },
        lineHeight: {
          lg: (1 * 1.5) + 'rem',
          md: (1 * 1.5) + 'rem',
          sm: (1 * 1.5) + 'rem',
          xs: (1 * 1.5) + 'rem',
          xxs: (1 * 1.5) + 'rem'
        }
      },
      small: {
        fontSize: {
          lg: '0.937rem',
          md: '0.937rem',
          sm: '0.937rem',
          xs: '0.937rem',
          xxs: '0.937rem'
        },
        lineHeight: {
          lg: (0.937 * 1.2) + 'rem',
          md: (0.937 * 1.2) + 'rem',
          sm: (0.937 * 1.2) + 'rem',
          xs: (0.937 * 1.2) + 'rem',
          xxs: (0.937 * 1.2) + 'rem'
        }
      },
      xSmall: {
        fontSize: {
          lg: '0.8125rem',
          md: '0.8125rem',
          sm: '0.8125rem',
          xs: '0.8125rem',
          xxs: '0.8125rem'
        },
        lineHeight: {
          lg: (0.8125 * 1.2) + 'rem',
          md: (0.8125 * 1.2) + 'rem',
          sm: (0.8125 * 1.2) + 'rem',
          xs: (0.8125 * 1.2) + 'rem',
          xxs: (0.8125 * 1.2) + 'rem'
        }
      },
      xxSmall: {
        fontSize: {
          lg: '0.6875rem',
          md: '0.6875rem',
          sm: '0.6875rem',
          xs: '0.6875rem',
          xxs: '0.6875rem'
        },
        lineHeight: {
          lg: (0.6875 * 1.2) + 'rem',
          md: (0.6875 * 1.2) + 'rem',
          sm: (0.6875 * 1.2) + 'rem',
          xs: (0.6875 * 1.2) + 'rem',
          xxs: (0.6875 * 1.2) + 'rem'
        }
      }
    }
  },
  button: {
    mainColor: {
      withLightBg: colors.primaryBrand1,
      withDarkBg: colors.primaryBrand1
    },
    textColor: {
      withLightBg: colors.white,
      withDarkBg: colors.white
    },
    border: {
      radius: '25px'
    },
    padding: '1em 1.7em',
    minWidth: '80px',
    fontSize: '1rem',
    fontFamily: 'Chivo'
  }
};