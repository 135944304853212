import styled, { css } from 'styled-components';

const defaultTabletViewHeight = '468px';
const defaultLargeViewHeight = '600px';

export const StyledCarouselContainer = styled.div`
    width: 100%;
    position: relative;
`;

export const StyledSlideContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    padding: 75px 15px 0 15px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        ${(props: any) => 
            props.layout == null ? css`
                flex-direction: row;
                padding: 0 37px 0 0;
            ` : css`
                padding: 0;
            `
        }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: row;
        padding: 0 37px 0 0;
    }
`;

export const StyledImageContainer = styled.div`
    ${(props: any) => !props.layout ? css`
        margin-bottom: 78.8px;
        padding: 5px 0;
    ` : ''};
    width: 100%;
    height: 213px;
    display: flex;
    order: 1;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        ${(props: any) => props.layout == null ? css`
            width: 48%;
            height: ${defaultTabletViewHeight};
            order: 0;
            margin: 0;
            padding: 0;
        ` : css`
            height: 437px;
            margin-bottom: 0;
            padding: 0;
    `}
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 47.7%;
        min-height: ${defaultLargeViewHeight};
        order: 0;
        margin: 0;
        padding: 0;
    }
`;

export const StyledImage = styled.div`
    width: 100%;
    background: url(${(props: any) => props.image}) no-repeat center;
    background-size: cover;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        height: ${defaultLargeViewHeight};
    }
`;

export const StyledImagePlaceholder = styled.div`
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    width: 100%;
    height: 213px;

    /* Random color value. We might not even use this placeholder */
    border: solid 10px #c2d1d9;
    background: #c2d1d9;
    border-radius: 2px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        height: ${defaultTabletViewHeight};
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        height: ${defaultLargeViewHeight};
    }
`;

export const StyledTextContainer = styled.div`
    width: 290px;
    padding-bottom: 25px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    order: 0;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        ${(props: any) => props.layout == null ? css`
            box-sizing: border-box;
            width: 50%;
            height: ${defaultTabletViewHeight};
            order: 1;
            padding-top: 139px;
            padding-bottom: 133px;
            align-items: center;
        ` : css`
            height: 256px;
            padding: 76px 133px 78px 127px;
            width: 66.15%;
            justify-content: space-evenly;
        `}
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        box-sizing: border-box;
        width: 50%;
        height: ${defaultLargeViewHeight};
        order: 1;
        padding-top: 157px;
        padding-bottom: 156px;
        align-items: center;

        ${(props: any) => props.layout ? css`text-align: left;` : ''}
    }
`;

export const StyledTitleContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 0;
    line-height: 0.9;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 361px;
        height: 150px;
    }
`;

export const StyledDescriptionContainer = styled.div`
    width: 100%;
    height: auto;
    padding: 0;
    line-height: 1.63;
    white-space: pre-wrap;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 361px;
        height: auto;
    }
`;

export const StyledIcon = styled.div`
    padding: 0;
    bottom: 40px;
    width: 23px;
    position: absolute;
    z-index: 1000;

    svg {
        path {
            fill: black
        }
    }

    :hover {
        svg {
            path {
                fill: ${(props: any) => props.theme.colors.primaryBrand1}
            }
        }
    }

    ${ (props: any) => getIconPosition(props.direction)}

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        ${(props: any) => props.direction === 'left' ? css`
            left: 50%;
        ` :
        css`
            right: 39px;
        ` }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        top: 365px;
        z-index: 0;
        ${(props: any) => props.direction === 'left' ? css`
            left: 56%;
        ` :
        css`
            right: 10%;
        ` }
    }
`;

const getIconPosition = (direction: string) => {
    return direction === 'left' ? css`
        left: 64.5px;
    ` :
     css`
        right: 64.5px;
    ` ;
};