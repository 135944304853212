import React from 'react';
import { StyledInfoContainer,
         StyledInfoPanel,
         StyledHeading,
         StyledLink,
         StyledText } from './InfoPanel.styled';
import { HelpContactInfo } from '../../../contents/getHelp';

export const InfoPanel = (props: any) => {
    return (
        <StyledInfoContainer>
                {
                    HelpContactInfo.map((info: {heading: string, links: string[]}, index: number) => (
                        <StyledInfoPanel key={`help-info-panel-${index}`}>
                            <StyledHeading color="black">
                                { info.heading }
                            </StyledHeading>

                            {
                                info.links.map((link: string, i: number) => (
                                    <StyledLink
                                        to="/gethelp"
                                        key={`help-info-${i}`}
                                        onClick={() => console.log(link)}
                                    >
                                        <StyledText>{ link }</StyledText>
                                    </StyledLink>
                                ))
                            }
                        </StyledInfoPanel>
                    ))
                }
        </StyledInfoContainer>
    );
};