import styled from 'styled-components';
import { Heading, Text } from '@movember/mo-gel';
import { Link } from 'react-router-dom';

export const StyledInfoContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        width: 689px;
        justify-content: center;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: 1017px;
        justify-content: flex-start;
    }
`;

export const StyledInfoPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin-bottom: 31px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        word-break: break-word;
        align-items: flex-start;
        text-align: left;
        height: 81px;
        width: 250px;
        margin-right: 31px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        width: auto;
        margin-right: 69px;
    }
`;

export const StyledHeading = styled(Heading)`
    font-size: 25px;
    line-height: 26px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        width: auto;
        font-size: 25px;
        line-height: 30px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        font-size: 25px;
        line-height: 30px;
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;
    pointer-events: none;
    text-decoration-color: ${(props: any) => props.theme.colors.primaryBrand1};
    color: black;

    :hover {
        color: ${(props: any) => props.theme.colors.primaryBrand1};
    }
`;

export const StyledText = styled(Text)`
    color: ${(props: any) => props.theme.colors.black};
    font-weight: bold;

    :hover {
        color: ${(props: any) => props.theme.colors.primaryBrand1};
    }
`;