import React, { Component } from 'react'
import { CarouselDots } from '../Carousel/CarouselDots'
import { StyledImage, ImageContainer } from './ImageSlide.styled'

export class ImageSlide extends Component<{url: any, logo: any, total: any, activeIndex: any}> {
    
    openLinkInNewTab = () => {
        window.open(this.props.url, "_blank")
    }
    
    render() {
        return (
            <ImageContainer>
                <StyledImage 
                onClick={this.openLinkInNewTab}
                src={this.props.logo} />
                <CarouselDots 
                total={this.props.total}
                activeIndex={this.props.activeIndex}/>
            </ImageContainer>
        )
    }
}