import UniversityOfWoolongongLogo from '../images/partners/UOW.png'
import CalgaryFoundationLogo from '../images/partners/CalgaryFoundation.png'
import MovemberLogo from '../images/partners/Movember.png';

export const partnerList = [
    {
        partnerType: "Partner",
        logo: CalgaryFoundationLogo,
        url: "https://calgaryfoundation.org/",
    },
    {
        partnerType: "     ",
        logo: MovemberLogo,
        url: "https://www.movember.com",
    },
    {
        partnerType: "Research Partner",
        logo: UniversityOfWoolongongLogo,
        url: "https://www.uow.edu.au/",
    }
];

