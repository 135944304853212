import styled from 'styled-components';
import { NavHashLink as NavLink } from 'react-router-hash-link';

export const StyledMenuToggle = styled.div`
  display: block;
  position: absolute;
  top: 50px;
  right: 50px;
 
  -webkit-user-select: none;
  user-select: none;

  input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;
    cursor: pointer;
    opacity: 0;
    z-index: 2;
    
    -webkit-touch-callout: none;

    :checked {
      ~ span {
        opacity: 1;
        transform: rotate(45deg) translate(-2px, -1px);
        background: #ffffff;

        :nth-last-child(3) {
          opacity: 0;
          transform: rotate(0deg) scale(0.2, 0.2);
        }

        :nth-last-child(2) {
          transform: rotate(-45deg) translate(0, -1px);
        }
      }
    }
  }

  span {
    display: block;
    width: 33px;
    height: 4px;
    margin-bottom: 5px;
    position: relative;
    background: #ffffff;
    border-radius: 3px;
    z-index: 1;
    transform-origin: 4px 0px;
    
    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
    
    :first-child {
      transform-origin: 0% 0%;
    }
    
    :nth-last-child(2) {
      transform-origin: 0% 100%;
    }
  }
`;

export const StyledMenuList = styled.ul`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  list-style-type: none;
  display: flex;

  ${(props: any) => props.showNavigation && `
    background: #000000;
    justify-content: center;
    position: fixed;
    overflow: auto;
  `}
`;

export const StyledListItem = styled.li`
  padding: 20px 0 0 0;
  font-size: 2rem;
`;

export const StyledNavLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
  color: #ffffff;
  width: 50%;
`;

export const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #ffffff;
  width: 50%;
`;

export const StyledNav = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 99;
`;

export const StyledLogo = styled.img`
  width: 141px;
  padding: 40px 20px;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    width: 175px;
  }
`;

export const SyledSubNavigationList = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 22px;
`;

export const StyledSubNavLink = styled(StyledNavLink)`
  width: 100%;
  padding: 10px 0;
`;