import React, { Component } from 'react';
import { StyledSubtitleContainer, StyledPartnerSubtitle } from './PartnerType.styled'




export class PartnerType extends Component <{type: any}>{
    render() {
        return (
            <StyledSubtitleContainer>
                <StyledPartnerSubtitle>{this.props.type}</StyledPartnerSubtitle>
            </StyledSubtitleContainer>  
        )
    }
}
