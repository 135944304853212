import React, { Component } from 'react';

import DiagonalSplitImage from '../../../images/page_banners/Banner_Coach.png';
import DiagonalSplitImageTablet from '../../../images/page_banners/Banner_Coach_Tablet.png';
import DiagonalSplitImageMobile from '../../../images/page_banners/Banner_Coach_Mobile.png';

import { CallToAction } from '../../../typings/CallToAction.type';
import { DiagonalSplitBanner } from '../../../components/DiagonalSplitBanner/DiagonalSplitBanner';
import { StyledCoachPage } from './Coach.styled';
import { Size } from '../../../typings/Size.type';
import { HalfImageHalfText } from '../../../components/HalfImageHalfText/HalfImageHalfText';
import HalfImage from '../../../images/page_banners/Banner_Coach.jpg';
import { HowItWorks } from '../../../components/HowItWorks/HowItWorks';
import { CoachesHowItWorksContent } from '../../../contents/howItWorks';
import { withRouter } from 'react-router-dom';

class CoachPage extends Component<any> { 
  render() {

  const diagonalBannerCallToAction: CallToAction = {
    heading: 'COACH',
    subHeading: 'Breaking the Ice helps hockey coaches develop the skills and knowledge they need to understand, recognize and support mental health and well-being in their athletes.',
    callToActionLabel: 'Sign Up',
    onClick: (): void => this.props.history.push('/contact')
  };

  const slides = [
    {
      image: HalfImage,
      heading: 'Your Role in your athletes’ Mental Health',
        // tslint:disable-next-line: max-line-length
      subHeading: 'Learn some real skills, like how you can take action if someone on your team is struggling with their mental health.'
    },
    {
      image: HalfImage,
      heading: 'How to tell if an athlete might be struggling',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Make sure that you have your athlete\'s back by understanding when they might be struggling, and how you could help them out.'
    },
    {
      image: HalfImage,
      heading: 'Having important conversations',
      // tslint:disable-next-line: max-line-length
      subHeading: 'Talking about mental health can be awkward. Learn how to start a conversation with athletes or parents.'
    },
      
  ];

  return (
    <StyledCoachPage>
      <DiagonalSplitBanner callToAction={diagonalBannerCallToAction} images={[DiagonalSplitImage, DiagonalSplitImageTablet, DiagonalSplitImageMobile]} size={Size.small}/>
      <HalfImageHalfText slides={slides} />
      <HowItWorks numberOfTimelineComponents={2} content={ CoachesHowItWorksContent } />
    </StyledCoachPage>
    );
  }
}

const Coach = withRouter(CoachPage);

export { Coach as CoachPage };