import styled from 'styled-components';

export const CarouselPrevArrowContainer = styled.div`
    position: relative;
    top: 50%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    padding-right: 20px;
    height: 20px;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
`;

export const CarouselNextArrowContainer = styled.div`
    position: relative;
    top: 50%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    padding-left: 20px;
    height: 20px;
    width: 20px;
    margin-left: auto;
    margin-right: auto;
`;


export const StyledIcon = styled.div`
    display: flex;
    justify-content: flex-start;

    svg {
        g {
            g:nth-of-type(2) {
                path {
                    fill: black
                }
            }
        }
    }
`;
