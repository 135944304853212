import React, { Component } from 'react'

import { 
    StyledNavigationItem,
    StyledImage,
    StyledLogoContainer,
    StyledSubtitleContainer,
    StyledPartnerSubtitle,
} from './Partner.styled'; 

export class Partner extends Component<{partnerType:any,url:any, logo:any}> {
    constructor(props:any) {
        super(props);
        this.state = {
            hover: true
        }
    };
    
    openLinkInNewTab = () => {
        window.open(this.props.url, "_blank")
    }

    handleMouseEnter= () => {
        // this.setState({ hover: true})
    };
    
    handleMouseLeave = () => {
        // this.setState({ hover: false})
    };



    render() {        
        return(
                <StyledNavigationItem>
                    <StyledLogoContainer 
                    onClick={this.openLinkInNewTab}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}>
                            <StyledImage src={this.props.logo} />
                    </StyledLogoContainer>
                    <StyledSubtitleContainer>
                        <StyledPartnerSubtitle>{this.props.partnerType}</StyledPartnerSubtitle>
                    </StyledSubtitleContainer>
                </StyledNavigationItem>
        );
    }

}

