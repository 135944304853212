import React, { Component } from 'react';

import { DesktopHeader } from './DesktopHeader/DesktopHeader';
import { MobileHeader } from './MobileHeader/MobileHeader';

export interface HeaderState {
  width: number;
}

export class Header extends Component<{}, HeaderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      width: 0
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    const { width } = this.state;
    const navLinks = [
      {
        title: 'Who\'s it for?',
        link: '/#whositfor'
      },
      {
        title: 'About',
        link: '/about'
      },
      {
        title: 'FAQ',
        link: '/faq'
      },
      {
        title: 'Get help',
        link: '/gethelp'
      },
      {
        title: 'Contact',
        link: '/contact'
      },
      {
        title: 'Research',
        link: '/research'
      },
      {
        title: 'Login',
        link: ''
      }
    ];

    const whoForNavLinks = [
      {
        title: 'Athletes',
        link: '/whositfor/athlete'
      },
      {
        title: 'Parents',
        link: '/whositfor/parent'
      },
      {
        title: 'Coaches',
        link: '/whositfor/coach'
      }
    ];

    const headerProps = {
      navLinks,
      whoForNavLinks
    };

    return(
    <>
      {width < 1280 ? <MobileHeader {...headerProps} /> : <DesktopHeader {...headerProps} />}
    </>
    );
  }
}
