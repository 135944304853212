import React from 'react';
import { StyledGetHelpContainer,
         StyledHeading,
         StyledButtonContainer,
         StyledGoToTopButton,
         StyledIcon,
         StyledLink
        } from './GetHelp.styled';
import { InfoPanel } from './InfoPanel/InfoPanel';
import { ReactComponent as Arrow } from '../../images/icons/right_icon.svg';

export const GetHelp = (props: any) => {
    return (
        <StyledGetHelpContainer>
            <StyledHeading level="h3" color="black">
            The following organizations provide support and further resources on mental health. <StyledLink to="/gethelp">If person is in crisis or life is in danger, call 911.
</StyledLink>
            </StyledHeading>
            <InfoPanel />
            <StyledButtonContainer>
                <StyledGoToTopButton onClick={gotoTop}>
                    <StyledIcon>
                        <Arrow />
                    </StyledIcon>
                </StyledGoToTopButton>
            </StyledButtonContainer>
        </StyledGetHelpContainer>
    );
};

function gotoTop(): void {
    if (window.scrollY > 0) {
        setTimeout(() => {
            window.scrollTo(0, window.scrollY - 20);
            gotoTop();
        }, 5);
    }
}