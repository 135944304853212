import { CallToAction } from '../typings/CallToAction.type';

export type HomePageContent = {
   heroBanner: CallToAction;
   diagonalBanner: CallToAction;
};

export const homePageContent: HomePageContent = {
  heroBanner: {
    heading: 'talk more, play better',
    callToActionLabel: 'Sign Up'
  },
  diagonalBanner: {
    heading: 'Breaking the Ice: Talk More, Play Better',
    subHeading: `When athletes feel mentally fit they can play better. Breaking the Ice teaches young hockey players how to talk about mental health, get help when needed and overcome life's challenges.

This program is multi-layered and includes educational workshops and materials for athletes, parents and coaches.
    `,
    callToActionLabel: 'Find out more'
  }
};