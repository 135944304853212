import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Heading, Text } from '@movember/mo-gel';

import Logo from '../../../images/BTI_logo_2020_white-blue.svg';
import { Link } from '../../../typings/Links.type';

import {
  SyledSubNavigationList, 
  StyledSubNavLink, 
  StyledNav, 
  StyledMenuList, 
  StyledLogo, 
  StyledMenuToggle, 
  StyledNavLinkContainer, 
  StyledListItem, 
  StyledNavLink,
  StyledLink
} from './MobileHeader.styled';

export interface MobileHeaderProps {
  navLinks: Link[];
  whoForNavLinks: Link[];
}

export interface MobileHeaderState {
  showNavigation: boolean;
  showSubNavigation: boolean;
}

export class MobileHeader extends Component<MobileHeaderProps, MobileHeaderState> {
  constructor(props: MobileHeaderProps) {
    super(props);

    this.state = {
      showNavigation: false,
      showSubNavigation: true
    };
  }

  handleInputChange = () => {
    const { showNavigation } = this.state;  
    this.setState( { showNavigation: !showNavigation });
  };

  navigationClick = (event: any) => {
    const { showNavigation } = this.state;
   
      this.setState( { showNavigation: !showNavigation });
  }

  subNavigationLinks = (whoForNavLinks: Link[], linkTitle: string) => {
    return (
      <SyledSubNavigationList>
        {linkTitle === 'Who\'s it for?' && whoForNavLinks.map((navLink: Link, index: number) => 
          <StyledSubNavLink 
            key={`sub-navigation-link-${index}`}
            to={navLink.link}
            onClick={this.navigationClick}
            role="button"
            >
            <Text>{navLink.title}</Text>
          </StyledSubNavLink>
        )}
      </SyledSubNavigationList>
    );
  }
  
  render() {
    const { navLinks, whoForNavLinks } = this.props;
    const { showNavigation, showSubNavigation } = this.state;
    
    return (
      <StyledNav>
        <StyledMenuList showNavigation={showNavigation}>
          {!showNavigation && <NavLink to="/"><StyledLogo src={Logo} /></NavLink>}
          <StyledMenuToggle>
            <input type="checkbox" checked={showNavigation} onChange={this.handleInputChange}/>
            <span />
            <span />
            <span />
            <div />
          </StyledMenuToggle>

          <StyledNavLinkContainer>
            {showNavigation && navLinks.map((navLink: Link, index: number) => 
              <React.Fragment key={`navigation-link-${index}`}>
               <StyledListItem>
                 {
                   navLink.title === 'Login' ?
                   <StyledLink 
                      href="https://cloud.scorm.com/sc/InvitationConfirmEmail?publicInvitationId=2bba9498-5893-4585-b47a-2a202d020923"
                      onClick={this.navigationClick}
                    >
                     <Heading level="h3"><>{navLink.title}</></Heading>
                   </StyledLink>
                   :
                  <StyledNavLink to={navLink.link} onClick={this.navigationClick}>
                    <Heading level="h3"><>{navLink.title}</></Heading>
                  </StyledNavLink>
                 }
                </StyledListItem>
               {showSubNavigation && this.subNavigationLinks(whoForNavLinks, navLink.title)}
              </React.Fragment>
            )}
          </StyledNavLinkContainer>
        </StyledMenuList>
      </StyledNav>
    );
  }
}
