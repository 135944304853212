import React, { Component } from 'react';
import { Heading, Button, Text } from '@movember/mo-gel';

import { StyledButtonContents, StyledCallToActionContainer } from './CallToAction.styled';
import { CallToAction } from '../../typings/CallToAction.type';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';

export interface CallToActionProps {
  callToAction: CallToAction;
  buttonColor?: string;
  buttonWidth?: string;
  headingLevel?: string;
  hoverColor?: string;
  color?: string;
}

export class CallToActionComponent extends Component<CallToActionProps> {
  render() {
    const { callToAction, buttonColor, buttonWidth, headingLevel } = this.props;
    const { heading, subHeading, onClick, callToActionLabel } = callToAction;
    return (
      <StyledCallToActionContainer hoverColor={this.props.hoverColor}>
        <Heading level={headingLevel} color={this.props.color || "white"} fontFamily="Passion One" marginBottom="20px">{heading}</Heading>
        {subHeading && <Text as="p" color={this.props.color || "white"} marginBottom="34px" display="block">{subHeading}</Text> }
        {callToActionLabel && 
          <Button 
            primary={true} 
            color={buttonColor} 
            width={buttonWidth} 
            onClick={onClick}>
              <StyledButtonContents>
              {callToActionLabel}<RightArrow/>
              </StyledButtonContents>
          </Button>}
      </StyledCallToActionContainer>
    );
  }
}
