import styled from 'styled-components';
import { Heading, Text, Button } from '@movember/mo-gel';

export const StyledPrivacyPage = styled.div`
    background: ${(props: any) => props.theme.colors.primaryBrand1};
    position: relative;
`;

export const StyledPrivacyHeading = styled(Heading)`
    padding: 145px 15px 109px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 193px 39px 132px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 243px 607px 152px 150px;
    }
`;

export const StyledPrivacyIntro = styled(Text)`
    padding: 72px 15px 65px;
    white-space: pre-wrap;
    color: ${(props: any) => props.theme.colors.white};
    background: ${(props: any) => props.theme.colors.primaryBrand3};

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 76px 39px 75px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 59px 345px 61px 150px;
    }
`;

export const StyledContentContainer = styled.div`
    box-sizing: border-box;
    padding: 64px 15px 67px;
    background: ${(props: any) => props.theme.colors.white};

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        padding: 90px 39px 85px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        padding: 152px 345px 61px 152px;
    }
`;

export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledContentHeading = styled(Heading)`
    font-size: ${(props: any) => props.fontSize};
    color: ${(props: any) => props.theme.colors.black};
    margin-bottom: 19px;
`;

export const StyledContentText = styled(Text)`
    white-space: pre-wrap;
    color: ${(props: any) => props.theme.colors.black};
`;

export const StyledContentBulletPoints = styled(StyledContentText)`
    padding-left: 64px;
`;

export const StyledIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    transform: rotate(-90deg);

    svg {
        g {
            g:nth-of-type(2) {
                path {
                    fill: black
                }
            }
        }
    }
`;

export const StyledButtonContainer = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 1px;
    bottom: 0;
    filter: drop-shadow(-1px -1px 3px rgba(0, 0, 0, .4));
    z-index: 1000;
`;

export const StyledGoToTopButton = styled(Button)`
    width: 51px;
    height: 39px;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    background: white;
    padding: 0;
    min-width: 0;
`;