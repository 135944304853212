import styled from 'styled-components';

export const StyledContainer = styled.div`
    width: 100%;
    display: flex;
    position: relative;
    height: 600px;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        height: auto;
    };
`;

export const StyledHeroContainer = styled.video`
    border-right: none;
    border-left: none;
    width: 100%;
    object-fit: cover;
`;

export const StyledHeroCallToAction = styled.div`
  position: absolute;
  transform: translate(-50%, 0);
  text-align: center;
  left: 50%;
  width: 80%;
  top: 50%;

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.xs}) {
    width: 50%;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    width: 100%;
    margin-top: 0;
    top: 60%;
  };

  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.md}) {
    margin-top: 0;
    top: 70%;
    width: 100%;
  };
  
  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
    text-align: left;
    left: 25%;
    width: 30%;
    top: 60%;
  };
`;