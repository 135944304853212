import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { StyledFaqPage, 
      StyledHeadingContainer,
      StyledFaqContact,
      StyledFaqContactInner,
      StyledFaqContent,
      StyledQuestionText,
      StyledAnswerContent,
      StyledFaqAccordion } from './Faq.styled';
import { Heading, Button } from '@movember/mo-gel';
import { ReactComponent as RightArrow } from '../../images/icons/right_icon.svg';

class FaqPage extends Component<any, any> {
  render() {
    const faqItems = [{
      question: `What is Breaking the Ice?`,
      content: <StyledAnswerContent>Movember Breaking the Ice is a training workshop that uses hockey to teach athletes, parents and coaches how to talk about mental health. The program reviews how to recognize mental health issues, what to do and when to get help. Athletes learn how to build resilience and overcome challenges in hockey and life.</StyledAnswerContent>
    }, {
      question: `Why is it needed?`,
      content: <StyledAnswerContent>The prevalence of mental health problems among adolescents is alarmingly high. With lower rates of accessing services than young women, young men and boys represent a group at high risk of developing mental health problems. Research has shown that organized sport is an effective and important avenue for supporting mental health.</StyledAnswerContent>
    }, {
      question: `When does Breaking the Ice take place?`,
      content: <StyledAnswerContent>Movember Breaking the Ice is delivered during regular hockey practice times. (Usually before, during or immediately after a regular practice). We come to you and make having mental health conversations easier.</StyledAnswerContent>
    }, {
      question: `Who participates?`,
      content: <StyledAnswerContent>Athletes, parents and coaches will each participate in interactive workshops with their group. Sessions for all groups are run on a single day, with a second workshop for athletes about a week later. We also provide online tools and info that can be accessed at any time.</StyledAnswerContent>
    }, {
      question: `How many workshops do I need to attend?`,
      content: <StyledAnswerContent>As part of the program, athletes will attend two 1-hour workshops where they’ll learn the skills that will help improve their understanding of mental health and resilience. Parents will attend one workshop developed specifically for parents and coaches will attend a workshop for coaches.</StyledAnswerContent>
    }, {
      question: `Why is my club taking part?`,
      content: <StyledAnswerContent>The prevalence of mental health problems among adolescents is alarmingly high. Young men and boys represent a group at high risk of developing mental health problems. Research has shown that organized sport is an effective and important avenue for supporting mental health.</StyledAnswerContent>
    }, {
      question: `Do I have to participate?`,
      content: <StyledAnswerContent>No, you do not need to participate in the workshops, or in workshop surveys. You are free to complete part, or none of the program as you wish. You will receive information prior to the program which will help you make a decision on whether you want to take part.</StyledAnswerContent>
    }, {
      question: `Can I withdraw from the program?`,
      content: <StyledAnswerContent>Yes, you can withdraw from the program at any time. If you choose to withdraw, this will not affect your relationship with your team, sport organization, or Movember in any way.</StyledAnswerContent>
    }, {
      question: `Will I be expected to treat mental health problems?`,
      content: <StyledAnswerContent>No, you will not be responsible for treating any mental health problems. The program is designed so that people will understand how to access help from an appropriate service, if necessary. The project team can help with any such queries throughout the project.</StyledAnswerContent>
    }, {
      question: `What if an athlete wants to participate but their parent doesn’t, can the athlete participate without the parents?`,
      content: <StyledAnswerContent>Yes, athletes can participate even if you (as their parent or guardian) or their coach do not wish to participate. However, your adolescent does need your consent to participate in the program.</StyledAnswerContent>
    }, {
      question: `As a parent can I be part of the athletes workshop? `,
      content: <StyledAnswerContent>The athlete’s workshop is just for adolescents. However, in the parent program we will let you know exactly what your adolescent has learned in their program so that you can be prepared.</StyledAnswerContent>
    }, {
      question: `Is the program offered to female athletes?`,
      content: <StyledAnswerContent>Movember Breaking the Ice can be delivered to any gender but is targeted at young men due to the disproportionate effect that poor mental health has on men and their lives. To learn more about how the program works, contact our expert Movember Coordinators.</StyledAnswerContent>
    }, {
      question: `How do I register or find out more?`,
      content: <StyledAnswerContent>To register your interest or find out more head to the contact us section of the website.</StyledAnswerContent>
    }, {
      question: `Is there anything I need to do before my workshops?`,
      content: <StyledAnswerContent>Athletes need to complete and return a consent form before taking part and there is a short survey to complete.</StyledAnswerContent>
    }, {
      question: `Is the program offered to girls' hockey leagues?`,
      content: <StyledAnswerContent>Movember Breaking the Ice can be delivered to any gender but is targeted at young men due to the disproportionate effect that poor mental health has on men and their lives. To learn more about how the program works, contact our expert Movember Coordinators.</StyledAnswerContent>
    }];

    return (
        <StyledFaqPage>
            <StyledHeadingContainer>
              <Heading level="h3">Frequently Asked Questions</Heading>
            </StyledHeadingContainer>
            <StyledFaqContent>
              <StyledFaqAccordion allowMultipleOpen={true}
                items={faqItems.map(faqItem => {
                  return {
                    header: <StyledQuestionText>{faqItem.question}</StyledQuestionText>,
                    content: faqItem.content
                  }
                })}
                >
              </StyledFaqAccordion>
            </StyledFaqContent>
            <StyledFaqContact>
              <StyledFaqContactInner>
                <Heading level="h5" color="white">Can't see your question here?</Heading>
                <Button 
                    primary={true}
                    width="194px"
                    onClick={() => this.props.history.push("/contact")}
                    >Contact us<RightArrow/></Button>
              </StyledFaqContactInner>
            </StyledFaqContact>
        </StyledFaqPage>
    );
  }
}

const component = withTheme(withRouter(FaqPage));
export { component as FaqPage };