import styled from 'styled-components';
import { Accordion } from '../../components/Accordion/Accordion';
import { StyledAccordionItem, StyledAccordionItemHeader, StyledAccordionItemHeaderOpen, StyledAccordionItemHeaderClosed, StyledAccordionItemContent } from '../../components/Accordion/Accordion.styled';

export const StyledFaqPage = styled.div`
    background: ${(props: any) => props.theme.colors.white};
    position: relative;
`;

export const StyledHeadingContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    height: 435px;
    padding: 145px 15px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    text-align: center;
    background: ${(props: any) => props.theme.colors.primaryBrand1}

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        text-align: left;
        padding: 178px 39px 133px;
        width: 690px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        text-align: left;
        padding: 242px 607px 158px 150px;
        width: 100%;
        height: 450px;
    }
`;

export const StyledFaqContent = styled.div`
    max-width: 1000px;
    margin: 80px auto;
    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
      margin: 100px auto;
  }
`;

export const StyledQuestionText = styled.p`
    margin: 0;
    font-weight: bold;
`;

export const StyledFaqAccordion = styled(Accordion)`
    ${StyledAccordionItem} {
      border-bottom: 1px solid ${(props: any) => props.theme.colors.secondaryBrand2};
      ${StyledAccordionItemHeader} {
        display: flex;
        flex-direction: row;
        padding: 20px 0;
        &:before {
          width: 20px;
          height: 20px;
          padding: 0 20px;
          flex-shrink: 0;
          font-size: 20px;
          line-height: 20px;
          text-align: center;
        }
      }
      ${StyledAccordionItemHeaderOpen} {
        &:before {
          content: '-';
        }
      }
      ${StyledAccordionItemHeaderClosed} {
        &:before {
          content: '+';
        }
      }
      ${StyledAccordionItemContent} {
        margin-left: 60px;
      }
    }
`;

export const StyledAnswerContent = styled.p`
  margin: 0;
  padding-bottom: 20px;
`;

export const StyledFaqContact = styled.div`
  background-color: ${(props: any) => props.theme.colors.primaryBrand3};
  padding: 4em 0;
`;

export const StyledFaqContactInner = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  h5 {
    margin: 0 1rem;
    text-align: center;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: normal;
  }
  Button {
    height: auto;
    :hover {
        background: ${(props:any) => props.theme.colors.buttonDarkHover}
    }
    > * {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      >:last-child {
        margin-left: 10px;
      }
    }
  }
  @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
    flex-direction: row;
    justify-content: center;
    h5 {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
`;