import Athlete from '../images/page_banners/Banner_Athlete.jpg';
import Parent from '../images/page_banners/Banner_Parent.jpg';
import Coach from '../images/page_banners/Banner_Coach.jpg';

export const slides = [
    {
      image: Athlete,
      link: '/whositfor/athlete',
      title: 'ATHLETES',
      text: `Successful athletes always find a way to push through when all seems lost. They're masters at tackling adversity and negotiating any challenge that stands in the way of success. Throughout the program, you'll learn the same skills champion athletes use to stay mentally fit and resilient.
      
As part of the program, athletes will attend two workshops. You'll learn how to start conversations about mental health and recognize warning signs of mental health problems. You'll also gain the skills to help a friend out who might be going through a tough time. At the end, you'll have sharper skills to overcome challenges in hockey and in life.
      `
    },
    {
      image: Parent,
      link: '/whositfor/parent',
      title: 'PARENTS',
      text: `Breaking the Ice helps parents understand, recognize and manage mental health issues in their family, including how to start a conversation when you think something is wrong. Throughout the program, you'll gain important skills and knowledge that underpin the mental health of the people in your life.

As part of the program, parents will attend one workshop. The session will help you recognize the warning signs of mental health problems and learn how to start a conversation about mental health. At the end, you'll also have a deeper understanding of the support channels available to you. 
      `
    },
    {
      image: Coach,
      link: '/whositfor/coach',
      title: 'COACHES',
      text: `Breaking the Ice helps coaches understand, recognize and manage mental health issues among their athletes. Throughout the program, coaches will gain important skills and knowledge that underpin youth mental health.

As part of the program, coaches will attend one workshop. This session will help you recognize the warning signs of mental health issues among young athletes. You'll learn how to start a conversation about mental health with players or parents. At the end, you'll also have a deeper understanding of the support channels available to you. 
      `
    },
  ];