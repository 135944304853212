import styled from 'styled-components';

export const StyledIcon = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;

    svg {
        circle {
            fill: ${(props: any) => props.isActive ? '#006af9' : 'transparent'};
        }
    }
`;
