import React from 'react';
import { StyledPrivacyPage, 
         StyledPrivacyHeading, 
         StyledPrivacyIntro, 
         StyledContentContainer, 
         StyledContent,
         StyledContentHeading,
         StyledContentText, 
         StyledContentBulletPoints,
         StyledIcon,
         StyledButtonContainer,
         StyledGoToTopButton } from './PrivacyPage.styled';
import { ReactComponent as Arrow } from '../../images/icons/right_icon.svg';

export const PrivacyPage = (props: any) => {

    const introText = `Any personal information collected through Movember’s Health Promotions websites including this site (breakingtheice.ca) is being collected by The Movember Group Pty Ltd as trustee for the Movember Canada Registered Charity number 84821 5604 RR0001 and or Movember Canada (incorporated not for profit 767531-3).

Personal information collected by Movember may be held on servers located in Canada, Australia, the UK or the USA . Where your information is transferred outside the country in which you are located, it will be subject to the laws of the country in which it is stored (which may have a lower standard of data protection than the country in which you reside).  `;
    
    const newsletterSub = `If you register on the Website to receive updates on men’s health, Movember will use Mailchimp to collect your name and email address and to send the updates to you. Mailchimp is an email service provider based in Georgia, USA and its servers are also located in the USA. Mailchimp’s privacy policy can be found here.  

We may also contact you from time to time to ask if you would like to participate in surveys or user testing. We use SurveyMonkey to conduct our surveys. SurveyMonkey is a company that provides software as a service to enable the development of online surveys. The information you submit in response to our surveys will be stored by SurveyMonkey on behalf of Movember in accordance with SurveyMonkey’s Privacy Policy located here. 

SurveyMonkey may handle or transfer your information in the United States and countries in which its affiliates and service providers are located or have servers. Further details can be found in SurveyMonkey’s Privacy Policy. Surveys are completely voluntary and you can opt out at any time.
`;

    const feedback = `You may also provide feedback on the Website, together with your email address (which we may use to follow up on your feedback). Movember uses Hotjar to collect this feedback. Hotjar is a software as a service provider based in Malta. All data that Hotjar collects is stored electronically in Ireland, Europe on the Amazon Web Services infrastructure. For more information about Hotjar, including information on how to opt out, please refer to Hotjar’s Privacy Policy.`;
    const whatAreCookies = `Cookies are small text files that are stored in your computer’s memory and hard drive when you visit certain web pages. They are used to enable websites to function or to provide information to the owners of a website.`;
    const whyUseCookies = `Cookies help us to provide customized services and information. For example, we use cookies on the Website to collect anonymous traffic data and also to improve your experience with the Website.

In broad terms, we use cookies on our Website for the following purposes:`;

    const analyticalPurposes = `•  We use analytical cookies that allow us to recognise, measure and track visitors to the website. This helps us to improve and develop the way the Website works, for example, by determining whether site visitors can find information easily, or by identifying the aspects of the Website that are of the most interest to them. For these purposes, we may store the following:
`;
    const bullets = `-  the name of the domain from which you accessed the internet
-  the date and time you accessed the Website
-  the advert or internet address of the Website from which you linked directly to the website
-  the pages you accessed while visiting the Website
-  the device from which you accessed the Website
-  the location from which you accessed the Website`;

    const usagePreferences = `•  Some of the cookies on the Website are activated when visitors make a choice about their usage of the Website. The Website then ‘remembers’ the settings preferences of the user concerned. This allows us to tailor aspects of the Website to the individual user.`;
    const sessionManagement = `•  The software that runs the Website uses cookies for technical purposes needed by the internal workings of our servers and application. For instance, we use cookies to keep track of information about a user’s session and determine which options or pages to display in order for the site to function.`;
    const functionalPurposes = `•  Functional purpose cookies store information that is needed by our applications to process and operate. For example, where requests within an application involve multiple stages, cookies are used to store the information from each stage temporarily, in order to facilitate completion of the overall request.`;
    const thirdPartyCookies = `•  When you visit the Website, you may receive cookies that are set by Google and HotJar. These cookies are used for the purposes described above. 

•  For more information about Google Analytics and cookies (including information on how to opt out), please check Google’s Privacy Policy and information on installing the Google Analytics opt-out browser add-on.

•  For more information about Hotjar and cookies, including information on how to opt out, please refer to Hotjar’s Privacy Policy.`;

    const cookiePreferences = `•  To make full use of the Website, your computer or mobile device will need to accept cookies, as the Website will not function properly without them. In addition, cookies are required in order to provide you with personalized features on the Website.`;
    const amendingCookiePreferences = `•  If you wish to remove cookies set by the Website on your browser in the future, you may delete them. The instructions for removing cookies from your computer or mobile device depend on the operating system and web browser you use. Please note, however, that withdrawing your agreement to the use of cookies on this Website will impair the functionality of the Website.`;
    const furtherInfo = `Further detail about how Movember handles your personal information can be found in our Privacy Policy, including information about:

•  how you may access and correct the personal information that Movember holds about you

•  how you may complain about a breach of privacy by Movember

Please contact us at privacy@movember.com if you have any queries about Movember and privacy.`;
    
    const changesToPrivacyStatement = `Movember may vary this Privacy Statement from time to time by publishing a revised version on the Website. Your continued use of the Website following such variation (and, in cases requiring your consent, the provision of that consent) will represent an agreement by you to be bound by the Privacy Statement as amended.`;
    
    return (
        <StyledPrivacyPage>
            <StyledPrivacyHeading level="h4">PRIVACY STATEMENT</StyledPrivacyHeading>
            <StyledPrivacyIntro tag="p" marginBottom="0">
                { introText }
            </StyledPrivacyIntro>
            <StyledContentContainer>
                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="25px">
                        NEWSLETTER SUBSCRIPTION AND SURVEYS
                    </StyledContentHeading>
                    <StyledContentText tag="p"> { newsletterSub } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px">
                        FEEDBACK
                    </StyledContentHeading>
                    <StyledContentText tag="p"> { feedback } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="30px">
                        COOKIES
                    </StyledContentHeading>
                    <StyledContentHeading level="h6" fontSize="25px">
                        What are cookies?
                    </StyledContentHeading>
                    <StyledContentText tag="p"> { whatAreCookies } </StyledContentText>
                </StyledContent>

                <StyledContent>
                    <StyledContentHeading level="h6" fontSize="25px">
                        WHY DO WE USE COOKIES?
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="19px"> { whyUseCookies } </StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Analytical purposes </StyledContentText>
                    <StyledContentText tag="p" marginBottom="19px"> { analyticalPurposes }</StyledContentText>
                    <StyledContentBulletPoints marginBottom="24px">{ bullets }</StyledContentBulletPoints>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Usage preferences </StyledContentText>
                    <StyledContentText tag="p" marginBottom="19px"> { usagePreferences }</StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Session management </StyledContentText>
                    <StyledContentText tag="p" marginBottom="22px"> { sessionManagement }</StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Functional purposes </StyledContentText>
                    <StyledContentText tag="p" marginBottom="22px"> { functionalPurposes }</StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Third party cookies </StyledContentText>
                    <StyledContentText tag="p" marginBottom="22px"> { thirdPartyCookies }</StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Your cookie preferences </StyledContentText>
                    <StyledContentText tag="p" marginBottom="22px"> { cookiePreferences }</StyledContentText>

                    <StyledContentText tag="p" fontWeight="bold" marginBottom="6px"> Amending cookie preferences </StyledContentText>
                    <StyledContentText tag="p" marginBottom="0px"> { amendingCookiePreferences }</StyledContentText>

                    <StyledContentHeading level="h6" fontSize="30px" marginTop="60px" marginBottom="22px">
                        FURTHER INFORMATION
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="0px"> { furtherInfo }</StyledContentText>

                    <StyledContentHeading level="h6" fontSize="30px" marginTop="60px" marginBottom="22px">
                        CHANGES TO THIS PRIVACY STATEMENT
                    </StyledContentHeading>
                    <StyledContentText tag="p" marginBottom="0px"> { changesToPrivacyStatement }</StyledContentText>
                </StyledContent>
            </StyledContentContainer>
            <StyledButtonContainer>
                <StyledGoToTopButton onClick={gotoTop}>
                    <StyledIcon>
                        <Arrow />
                    </StyledIcon>
                </StyledGoToTopButton>
            </StyledButtonContainer>
        </StyledPrivacyPage>
    );
};

function gotoTop(): void {
    if (window.scrollY > 0) {
        setTimeout(() => {
            window.scrollTo(0, window.scrollY - 150);
            gotoTop();
        }, 5);
    }
}