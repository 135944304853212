import styled from 'styled-components';
import { Text } from '@movember/mo-gel'

export const StyledSubtitleContainer = styled.div`
  visibility: visible;
  display: block;
  text-align: center;
  align-items: center; 
  justify-content: center; 
  margin-right: auto;
  margin-left: auto;
  padding-top: 40px;
  text-decoration: none;
  height: 40px;
`;

export const StyledPartnerSubtitle = styled(Text)`
  visibility: visible;
  height: 40px;
  text-decoration: none;
  max-width: 75%;
  color: ${(props: any) => props.theme.colors.black};
`;