export const HowItWorksContent = {
    heading: "HOW IT WORKS",
    introText: "Breaking the Ice is delivered at the rink — right where hockey teams already are. We come to you and make having mental health conversations easier.",
    subsections: [
        {
            subheading: "WHEN ARE WORKSHOPS HELD?",
            text: "During regular practice times. (Usually before, during or immediately after a regular practice).",
            link: ""
        },
        {
            subheading: "WHO PARTICIPATES?",
            text: "Athletes, parents and coaches will each participate in interactive workshops with their group. Sessions for all groups are run on a single day, with a second workshop for athletes about a week later. We also provide online tools and info that can be accessed at any time.",
            link: ""
        },
        {
            subheading: "WANT TO SIGN UP OR LEARN MORE? CONTACT US",
            text: "Contact Us",
            link: "/contact"
        }             
    ],
}

export const WhyItWorksContent = {
    heading: "WHY IT WORKS",
    introText: "Breaking the Ice is based on Movember-funded research. It engages sporting organizations as a way to improve the mental health and resilience of young athletes",
    subsections: [
        {
            subheading: "WE USE THE POWER OF SPORT",
            text: "The program is effective because it uses hockey to help participants acquire important skills and knowledge. The program is run at the rink to engage participants and emphasize lessons that can be taught through hockey.",
            link: ""
        },
        {
            subheading: "WE ENGAGE ALL STAKEHOLDERS",
            text: "With programs for athletes, parents and coaches, Breaking the Ice can help young hockey athletes and their support networks promote mental health, resilience and wellbeing.",
            link: ""
        },
        {
            subheading: "WE FOCUS ON THE SKILLS YOU NEED",
            text: "Breaking the Ice targets both mental health literacy and resilience. When combined, these skills can underpin mental health and wellbeing over the decades to come.",
            link: ""
        }                
    ],
}