import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledFooter = styled.div`
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 532px;
    width: 100%;
    padding: 59px 20px 62px;
    box-sizing: border-box;
    background: ${(props: any) => props.theme.colors.black};

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        height: 284px;
        padding: 58px 103px 65.8px 127px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        height: 227px;
        padding: 67px 145.2px 66.2px 150px;

        p {
            text-align: left;
        }
    }
`;

export const StyledContainer = styled.div`
    height: 335px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: center;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        align-items: center;
        height: 97px;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        height: 43px;
        flex-direction: row;
    }
`;

export const StyledLinks = styled.div`
    height: 155px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        height: auto;
        width: 538px;
        order: 1;
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        order: 0
    }
`;

export const StyledLink = styled(Link)`
    text-decoration: none;

     :hover {
         p {
            color: ${(props: any) => props.theme.colors.primaryBrand1};
         }
     }
`;

export const StyledPartners = styled.div`
    height: 128px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.sm}) {
        flex-direction: row;
        width: 219.3px;
        height: auto;

        svg {
            width: 103.6px;
            height: 33px;
        }

        img {
            width: 81.2px;
        }
    }

    @media screen and (min-width: ${(props: any) => props.theme.breakpoints.lg}) {
        flex-direction: row;
        width: 285.8px;
        height: auto;

        svg {
            width: 135px;
            height: 43px;
        }

        img {
            width: 105.8px;
        }
    }
`;